import React from "react"
import Scrollspy from "react-scrollspy"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Footer from "../components/footer"
import Recommendations from "../components/recommendations"
import Preview from '../components/Preview'

const Chrome = props => (
    <Layout>
      <Preview/>

        <Hero
            title="Chrome Mobile"
            summary="Redesigning the tab switching experience on Google chrome mobile 🌍"
            cover="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610882290/chrome/Chrome-cover.png"
        />

        <div class="grid md:grid-cols-3 px-6 md:px-24">
            <div class="text-left md:w-7/12 hidden md:block">
                <div
                    data-sal="slide-right"
                    data-sal-duration="900"
                    data-sal-delay="2000"
                    data-sal-easing="ease"
                    class="sticky top-32 flex flex-col gap-1.5 ml-2">
                    <p class="container font-semibold text-theme-text py-2 text-sm tracking-wider text-left">
                    OVERVIEW ⤵
          </p>
                    <Scrollspy
                        items={[
                            "section-1",
                            "section-2",
                            "section-3",
                            "section-4",
                            "section-5",
                        ]}
                        class="container text-base ml-8 w-8/12 py-1.5 rounded-sm cursor-pointer"
                        currentClassName="text-main-text container -ml-4 my-1 px-4 w-auto py-1.5 rounded-sm border-orange-600 border-l-4 bg-secondary font-bold">
                        <li class="">
                            <a href="#section-1">About project</a>
                        </li>
                        <li class="">
                            <a href="#section-2">The Challenge</a>
                        </li>
                        <li class="">
                            <a href="#section-3">Exploring solutions</a>
                        </li>
                        <li class="">
                            <a href="#section-4">Validating the solution</a>
                        </li>
                        
                    </Scrollspy>

                    {/* <div class="bg-secondary bg-special-500 rounded-sm p-5 my-12">
                        <blockquote class="text-left w-auto"><p>Learn more about my skills and experience</p></blockquote>

                        <Link to="https://res.cloudinary.com/dcj89ranh/image/upload/v1641133338/TrustScore/Badara_Olawale_Resume.pdf" target="_blank" data-sal="slide-up"
                            data-sal-duration="700"
                            data-sal-delay="310"
                            data-sal-easing="ease" class="transform transition duration-500 ease-in-out bg-main-text py-3 px-5 mt-4 text-secondary shadow-lg rounded-full float-left font-semibold text-sm hover:shadow-2xl hover:opacity-90">View my Resume</Link>


                </div> */}
                </div>
            </div>

            <div class="divide-y divide-divider md:col-span-2 grid grid-cols-1 gap-8 md:gap-12">
                <section id="section-1" class="current">
                    <p class="text-2xl font-semibold text-left pt-8 pb-4">About project</p>
                    <div class="subpixel-antialiased md:text-lg md:text-l font-base leading-7 md:leading-8 pb-8 text-left break-words">
                        <p>
                            I decided to explore solving a problem on Chrome Mobile Browser.
                            As it is one of the digital products I use daily I already experienced some painpoints but
                            wasn’t sure if other users have a similar experience so I sort to validate this by checking reviews for the app on the Playstore.
            </p>
                    </div>

                    <div class="md:flex gap-8">
                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold pb-2 text-left">
                                ROLE IN PROJECT
                            </p>
                            <p class="text-base md:text-l font-base text-left leading-7">
                                UX Designer • Research and Visual Design
                    </p>
                        </div>

                        <div class="rounded-sm bg-secondary p-4">
                            <p class="text-sm md:text-md tracking-wider font-semibold text-left pb-2 pt-8 md:pt-0">
                                PROJECT GOAL(s)
                        </p>
                         
                                <p class="text-base md:text-l font-base text-left leading-7">
                                    Improve the experience of billions of chrome mobile users
                </p>

                 
                        </div>
                    </div>
                </section>

                <section id="section-2" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">
                        The Challenge
          </p>
                    <p class="subpixel-antialiased text-base md:text-l font-base leading-7 pb-4 text-left">
                        I headed to Google Playstore to gain insights on what users of the browser complain about
                        and one that resonated with many users were complaints about switching tabs.
                        I further investigated this problem by interviewing some users, gathering more reviews and documenting 
                        issues I have experienced myself.
   
          </p>
                   

                    <p class="text-xl font-semibold text-left pt-8 pb-3">Storyboarding the challenge</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 pb-4 text-left break-words">
                        In order to clearly understand the challenge I visually represented it using a storyboard capturing emotions a user feels when 
                        experiencing painpoints.  
          </p>
                    
            <div class="grid grid-cols-2 md:grid-cols-3 gap-4 bg-secondary md:pt-2 md:pb-4 md:pl-2 pr-2 md:pr-4">
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885780/chrome/Board-1.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885780/chrome/Board-2.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885781/chrome/Board-3.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885781/chrome/Board-4.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885781/chrome/Board-5.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610885781/chrome/Board-6.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                    </div>
                    <p class="subpixel-antialiased py-0 text-sm font-base leading-7   pb-4 text-center text-gray-300">
                        Storyboard highlighting the main issue with switching tabs
          </p>
                    
                   

                    <p class="text-xl font-semibold text-left pt-8 pb-4">Review from users</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        There are thousands of reviews about the Chrome Mobile app on the Playstore with users sharing 
                        what they dislike about the tab switching experience.
          </p>

                  
                    
                  <div class="grid grid-cols-1 md:grid-cols-3 gap-1 bg-secondary">
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-1.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-2.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-3.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-4.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-5.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610887675/chrome/Review-6.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                    </div>
                    <p class="subpixel-antialiased py-0 text-sm font-base leading-7 pb-4 text-center text-gray-300">
                        Reviews from users on the Playstore
          </p>
                        
          <p class="text-xl font-semibold text-left pt-8 pb-4">Insights from interviewing users</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        I spoke with some users of the chrome mobile app and got some valuable feedback on how they interact with the chrome switching feature.
          </p>

                  
                    
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610888481/chrome/Interview-1.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610888481/chrome/Interview-2.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610888481/chrome/Interview-3.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610888481/chrome/Interview-4.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                       
                    </div>
                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Insights from interviews
          </p>
                        
          <p class="text-xl font-semibold text-left pt-8 pb-4">Deep dive into the current design</p>
                    
                  <div class="md:flex">
                      <div>
                          <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610892194/chrome/Desktop_research-A.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                      </div>
                        <div>
                             <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610892467/chrome/Desktop_research-B.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        </div>
                       
                       
                       
                    </div>
                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Problems with the UI annotated
          </p>

          <p class="text-xl font-semibold text-left pt-8 pb-4">The challenge defined</p>
          <div class="md:flex gap-8">
                        <div class="rounded-sm bg-secondary bg-opacity-10 p-4 ">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.07" cx="30" cy="30" r="30" fill="#ECB22E"/>
<path d="M17 23.0177C16.3925 23.0177 15.9 23.5102 15.9 24.1177V42C15.9 42.6075 16.3925 43.1 17 43.1H43.549C44.1565 43.1 44.649 42.6075 44.649 42V24.1177C44.649 23.5102 44.1565 23.0177 43.549 23.0177H17ZM43.4294 40.9804C43.4294 41.4775 43.0265 41.8804 42.5294 41.8804H18.0196C17.5226 41.8804 17.1196 41.4775 17.1196 40.9804V25.1373C17.1196 24.6402 17.5226 24.2373 18.0196 24.2373H42.5294C43.0265 24.2373 43.4294 24.6402 43.4294 25.1373V40.9804Z" fill="#ECB330" stroke="#ECB22E" stroke-width="0.2"/>
<path d="M17.5294 22.1981H43.0196C43.3552 22.1981 43.6294 21.9239 43.6294 21.5883C43.6294 21.2527 43.3552 20.9785 43.0196 20.9785H17.5294C17.1938 20.9785 16.9196 21.2527 16.9196 21.5883C16.9196 21.9239 17.1938 22.1981 17.5294 22.1981Z" fill="#ECB330" stroke="#ECB22E" stroke-width="0.2"/>
<path d="M19.0588 20.1588H41.4902C41.8258 20.1588 42.1 19.8846 42.1 19.549C42.1 19.2134 41.8258 18.9392 41.4902 18.9392H19.0588C18.7232 18.9392 18.449 19.2134 18.449 19.549C18.449 19.8846 18.7232 20.1588 19.0588 20.1588Z" fill="#ECB330" stroke="#ECB22E" stroke-width="0.2"/>
<path d="M20.5882 18.1196H39.9608C40.2964 18.1196 40.5706 17.8454 40.5706 17.5098C40.5706 17.1742 40.2964 16.9 39.9608 16.9H20.5882C20.2526 16.9 19.9784 17.1742 19.9784 17.5098C19.9784 17.8454 20.2526 18.1196 20.5882 18.1196Z" fill="#ECB330" stroke="#ECB22E" stroke-width="0.2"/>
</svg>

                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            Difficulty in finding previously opened tabs
              </p>
                        </div>

                        <div class="rounded-sm bg-secondary p-4 ">
                        <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.07" cx="30" cy="30" r="30" fill="#E01E5A"/>
<path d="M18.4688 20.421V20.3872C18.4869 19.3261 19.3557 18.4688 20.4207 18.4688H39.5787C40.6553 18.4688 41.531 19.3447 41.531 20.421V39.579C41.531 40.6556 40.655 41.5312 39.5787 41.5312H20.421C19.3444 41.5312 18.4688 40.6553 18.4688 39.579V20.421ZM20.421 43.1H39.579C41.5207 43.1 43.1 41.5207 43.1 39.579V20.421C43.1 18.4796 41.5207 16.9 39.579 16.9H20.421C18.4796 16.9 16.9 18.4796 16.9 20.421V39.579C16.9 41.5207 18.4796 43.1 20.421 43.1Z" fill="#E3356B" stroke="#E3356B" stroke-width="0.2"/>
<path d="M24.3823 35.6176L24.3824 35.6177C24.5356 35.7706 24.7364 35.8471 24.9369 35.8471C25.1374 35.8471 25.3382 35.7706 25.4915 35.6177L25.4915 35.6176L30 31.1089L34.5088 35.6174L34.5088 35.6174C34.6621 35.7703 34.8626 35.8468 35.0634 35.8468C35.2635 35.8468 35.4646 35.7704 35.6179 35.6174L35.618 35.6173C35.9242 35.3108 35.9242 34.8145 35.618 34.5082L35.5472 34.5789L35.618 34.5082L31.1092 29.9997L35.6174 25.4912L35.6174 25.4912C35.9236 25.1846 35.9236 24.6886 35.6174 24.3821L35.6173 24.382C35.3108 24.0758 34.8148 24.0758 34.5082 24.382L34.5082 24.382L30 28.8905L25.4912 24.3823L25.4912 24.3823C25.1847 24.0761 24.6886 24.0761 24.3821 24.3823L24.382 24.3824C24.0758 24.6889 24.0757 25.1852 24.382 25.4915L24.382 25.4915L28.8908 29.9997L24.3823 34.5087C24.3823 34.5087 24.3823 34.5087 24.3823 34.5087C24.076 34.815 24.0761 35.3111 24.3823 35.6176Z" fill="#E3356B" stroke="#E3356B" stroke-width="0.2"/>
</svg>

                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            No affordance in helping users recover from errors
              </p>
                        </div>
                    </div>
                    
                </section>



                <section id="section-3" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Exploring solutions</p>
                   
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                     I started out exploring solutions by defining how might we questions based on the challenge defined, this questions guided the
                     ideation process and helped to validate the ideas on a preliminary basis.</p>
                     <p class="subpixel-antialiased text-base md:text-l font-base leading-7 text-left pb-4 break-words">
                 These questions were;</p>

                    <div class="md:flex gap-8">
                        <div class="flex rounded-sm bg-secondary bg-opacity-10 p-4 gap-2">
                        <svg class="pt-2" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="21" cy="21" r="21" fill="#F69219"/>
<circle cx="21.0002" cy="20.9999" r="12.4091" fill="#F69219"/>
<path d="M18.4379 19.1838C19.3684 19.1838 20.0081 19.0675 20.5751 18.5005V26.3076H22.4942V16.1307H20.6914C20.2407 16.9885 19.6155 17.4101 18.4379 17.4101V19.1838Z" fill="white"/>
</svg>

                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            How might we allow users easily find/identify open tabs?
              </p>
                        </div>

                        <div class="flex rounded-sm bg-secondary p-4 gap-2">
                        <svg class="pt-2" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle opacity="0.1" cx="21" cy="21" r="21" fill="#F69219"/>
<circle cx="21.0002" cy="20.9999" r="12.4091" fill="#F69219"/>
<path d="M17.4525 23.941V25.3076H24.3728V23.5339H20.3165L22.5991 21.3095C23.9221 20.0302 24.3728 19.2015 24.3728 17.8494C24.3728 16.192 23.2097 14.9272 20.9562 14.9272C18.7755 14.9272 17.4525 16.3665 17.4525 17.9512C17.4525 18.4018 17.5106 18.7798 17.6415 19.0997L19.5024 18.7508C19.4297 18.5618 19.3715 18.3437 19.3715 18.0529C19.3715 17.137 20.0258 16.6136 20.9562 16.6136C21.9303 16.6136 22.5119 17.1952 22.5119 18.0675C22.5119 18.8089 22.1484 19.3323 21.3197 20.1465L17.4525 23.941Z" fill="white"/>
</svg>
                            <p class="text-base md:text-l pt-2 font-base text-left leading-7">
                                
                            How might we design for errors while switching/closing tabs?
              </p>
                        </div>
                    </div>

                    <p class="subpixel-antialiased py-8 text-base md:text-l font-base leading-7 text-left break-words">
                     Armed with insights and how might we questions, I got started visually representing ideas and validated the sketches
                     using set interface heuristics.</p>

                    <div class="md:flex mt-4 gap-8 bg-secondary p-6">
                      <div>
                          <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610892906/chrome/Idea_A.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                      </div>
                        <div>
                             <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610892906/chrome/Idea_B.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        </div>
                       
                       
                       
                    </div>
                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Rough sketches of ideas
          </p>

          <p class="text-2xl font-semibold text-left pb-4 pt-8">Deep dive into the solution</p>

          <div class="md:flex">
                      <div>
                          <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610893593/chrome/Solution-1.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                      </div>
                        <div>
                             <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610893589/chrome/Solution-2.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        </div>
                       
                    </div>

                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Annotating improvements to the UI
          </p>


                </section>

                <section id="section-4" class="current">
                    <p class="text-2xl font-semibold text-left pb-4 pt-8">Validating the solution</p>
                    <p class="subpixel-antialiased py-2 text-base md:text-l font-base leading-7 text-left break-words">
                        I shared the prototype with the same users I intervied previously and got their feedback
                        on the new solution.
          </p>

                  
                    
                  <div class="grid grid-cols-1 md:grid-cols-2 gap-1">
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610893902/chrome/Validation-1.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610893902/chrome/Validaton-2.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610991391/chrome/Validation-3.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                        <img
                        src="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610893902/chrome/Validation-4.svg"
                        class="mx-auto"
                        alt="img-alt"/>
                       
                    </div>

                    <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                        Feedback from users after interacting with the new solution
          </p>

          <div class="mt-4 md:mt-16">

              
          <iframe allowtransparency="true" title="Wistia video player" allowFullscreen frameborder="0" scrolling="no" class="wistia_embed w-full" name="wistia_embed" src="https://fast.wistia.net/embed/iframe/mhcqp6mevc" height="470"></iframe>
          <p class="subpixel-antialiased text-sm font-base leading-7 py-4 text-center text-gray-300">
                       Video prototype of the redesign
          </p>
        </div>
                </section>

                
            </div>
        </div>
        <Recommendations projectName="chrome" />
        <Footer />

        
    </Layout>
)

export default Chrome
